import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledH1 = styled.h1`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const PageTeamHeroSection = () => (
  <div style={{ display: "grid" }}>
    <StaticImage
      style={{
        gridArea: "1/1",
        height: 450,
      }}
      width={1920}
      layout="constrained"
      alt=""
      src="../../images/header/hero-team.jpg"
      placeholder='tracedSVG'
      formats={["auto", "webp", "avif"]}
    />
    <div
      style={{
        gridArea: "1/1",
        position: "relative",
        placeItems: "center",
        display: "grid",
      }}
    >
      <div className='w-full max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto'>
        <div className='w-11/12 flex flex-col items-center mx-auto'>
          <StyledH1 className='text-3xl md:text-5xl font-extrabold text-white uppercase text-center'>
            <span className='leading-tight'>Meet Your Team</span>
          </StyledH1>
          <p className="text-xl text-white font-medium text-center mt-4">We are a team of hard working, passionate, technology enthusiasts who believe in the work that we do, and know that working together is the best way to deliver outstanding service. Our mission is to deliver Peace of Mind for our partner clients when it comes to IT solutions.</p>

          <div className="flex flex-col md:flex-row items-center justify-between md:space-x-6">
            <a
              href="https://itproactive.bamboohr.com/jobs/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="bg-red-600 hover:bg-red-700 text-white border border-red-600 font-display px-8 py-4 text-base md:text-xl font-semibold tracking-wide rounded mt-8 shadow-lg">
                Join Our Team
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PageTeamHeroSection