import * as React from "react"

import Layout from "../components/layout/layout"
import SEOnew from "../components/seo-new"
import CapabilitiesSection from "../components/sections/capabilities-section"
import BenefitsSection from "../components/sections/benefits-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"
import PageTeamHeroSection from "../components/sections/page-team-hero-section"
import PageTeamSection from "../components/sections/page-team-section"

const TeamPage = () => (
  <Layout>
    <SEOnew  title="Meet Your Team" noIndex />
    <PageTeamHeroSection />
    <PageTeamSection />
    <BenefitsSection />
    <QuickQuoteSection />
    <CapabilitiesSection />
  </Layout>
)

export default TeamPage
