import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const PageTeamSection = () => (
  <div className="bg-white w-full border-b">
    <div className="w-11/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-12 mx-auto my-12">
      <div>
        <StaticImage
          src="../../images/team/david-defrancis.jpeg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          David DeFrancis
        </h2>
        <p className="text-gray-800 italic mb-3">Owner/Founder</p>
        <hr />
        <p className="text-gray-900 mt-3">
          David founded DCTS IT Solutions in 1998 with a focus on process,
          organization and efficiency. He has a degree in Business
          Administration and holds many IT certifications including Microsoft
          Certified System Engineer (MCSE).
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/dan-hido.jpeg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Dan Hido
        </h2>
        <p className="text-gray-800 italic mb-3">IT Director</p>
        <hr />
        <p className="text-gray-900 mt-3">
          Dan is an IT veteran specialized in infrastructure, security, custom
          integrations, compliance, databases and business solutions. Bringing
          20+ years of IT experience throughout the medical, hospitality,
          telecom, manufacturing, construction, legal and financial industries.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/ellis-cheng.jpeg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Ellis Cheng
        </h2>
        <p className="text-gray-800 italic mb-3">Office Manager</p>
        <hr />
        <p className="text-gray-900 mt-3">
          Ellis is a graduate of University of California, Irvine, with a degree
          in Psychology and Social Behavior. She joined the IT Proactive team to
          provide administrative and accounting support, working alongside David
          to keep the office running smoothly.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/alan-bell.jpeg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Alan Bell
        </h2>
        <p className="text-gray-800 italic mb-3">Technical Project Manager</p>
        <hr />
        <p className="text-gray-900 mt-3">
          Alan is a Microsoft Certified Systems Engineer who has spent the last
          two decades managing networks for financial, manufacturing,
          entertainment, and legal fields. He views himself as a technology
          ambassador, and enjoys translating from computer to English.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/tyler-dehaven.jpeg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Tyler DeHaven
        </h2>
        <p className="text-gray-800 italic mb-3">Service Desk Manager</p>
        <hr />
        <p className="text-gray-900 mt-3">
          Tyler was in the Army for 6 years as Multi Channel Transmission System
          Operator before joining us at IT Proactive. He is currently in pursuit
          of obtaining his MCSE and is extremely passionate about computer
          information systems.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/robert-macalino.jpg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Robert Macalino
        </h2>
        <p className="text-gray-800 italic mb-3">vCIO</p>
        <hr />
        <p className="text-gray-900 mt-3">
          Robert was born and raised in Brooklyn New York and graduated from the
          University of Maryland. He has over 25 years of experience in the
          technology space in both Business Development and Program Management.
          He has a broad range of experience in establishing and managing
          business relationships with suppliers, vendors, and business partners,
          with the goal of customer success, satisfaction, and implementing
          innovative ideas with a creative, yet practical focus on solutions.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/mickey-kennedy.jpeg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Mickey Kennedy
        </h2>
        <p className="text-gray-800 italic mb-3">
          Information Technology, Risk, & Strategy Consultant
        </p>
        <hr />
        <p className="text-gray-900 mt-3">
          Over 8 Years experience in business network information technology.
          Currently focused on Technology Infrastructure consulting;
          specifically helping businesses, reduce costs, and improve
          efficiencies, and make critical decisions related to their Information
          technology Stack.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/tom-brown.jpg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Tom Brown
        </h2>
        <p className="text-gray-800 italic mb-3">Level II Generalist</p>
        <hr />
        <p className="text-gray-900 mt-3">
          Tom is a Microsoft Certified Professional with over 17 years of
          experience in the IT field, which includes 10 years as a network
          administrator in the auto insurance industry. He is currently in
          pursuit of his Microsoft Certified Systems Engineer certificate.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/jonathan-smith.jpg"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Jonathan Smith
        </h2>
        <p className="text-gray-800 italic mb-3">Level I Generalist</p>
        <hr />
        <p className="text-gray-900 mt-3">
          Jonathan is a business professional from the Petroleum Industry with
          over 17 years of experience in IT with an emphasis on Manufacturing,
          Branding, Technical Writing, PM, Analysis, Lean Six Sigma and
          Continuous Improvement. He keeps a sharp eye on emerging technologies
          and is always brainstorming a more efficient process.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/cj-bell.png"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          CJ Bell
        </h2>
        <p className="text-gray-800 italic mb-3">
          Technology Alignment Manager
        </p>
        <hr />
        <p className="text-gray-900 mt-3">
          CJ graduated from Universal Technical Institute with a degree in
          Industrial Technology. He is a business professional from the Special
          Effects Industry, with over 14 years of experience in Procurement
          Management, Logistics, OEM Fabrication, and Information Technology. He
          has strong investigative instincts, as well as an understanding of the
          harmony between technology and business operations. As well as how
          that can affect a company’s productivity and growth. He strives to
          make our client experience with technology as seamless and beneficial
          as possible. Not only for today’s work but looking ahead to the
          future.
        </p>
      </div>

      <div>
        <StaticImage
          src="../../images/team/josh-kerwin.png"
          width={580}
          layout="constrained"
          alt=""
          placeholder="tracedSVG"
          formats={["auto", "webp", "avif"]}
          className="rounded-md shadow-lg"
        />
        <h2 className="text-site-lightblue text-3xl font-bold mt-6 mb-1">
          Josh Kerwin
        </h2>
        <p className="text-gray-800 italic mb-3">Technical Assistant</p>
        <hr />
        <p className="text-gray-900 mt-3">
          After years in manufacturing quality control, Josh transitioned into
          the tech industry by joining IT Proactive as a Technical Assistant. He
          is working towards getting A+ and Network+ certified, and enjoys the
          fact that his position allows him both client interaction and hands-on
          technical work.
        </p>
      </div>
    </div>
  </div>
)

export default PageTeamSection
